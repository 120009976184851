.price-head {
  font-weight: 700;
  color: #9FA2B4;
  font-family: 'Mulish', sans-serif !important;
  font-size: 14px !important;
}

.action-item {
  display: flex;
  transform: translateX(-120px) !important;
}

.package {
  display: flex;
}